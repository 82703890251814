module.exports = {
  "strings": {
    "try_another_photo": "別の写真を試す",
    "go_back": "進む",

    "landing__title": "AIアーティストによって違う<br />自分の姿をチェック",
    "landing__select_photo_button": "写真をアップロード",

    "processing_text_0": "信じられないものが近づいています・・・",
    "processing_text_1": "お友達も気に入ることでしょう 😍",
    "processing_text_2": "もうすぐで完成します。<br />数秒で驚きが訪れます。",
    "processing_text_3": "結果がどのようになるかおわかりになりますか？",

    "create__select_one_result": "一番好きな結果を1つ選択し、[次へ]をタップして続行します",
    "create__select_two_results": "次に、コラージュの作成に最も適していると思う結果を2つ選択します",
    "create__next_button": "次へ",
    "create__create_collage_button": "コラージュを作成",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "エラーが生じました。。。",
  },
};

