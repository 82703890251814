import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import * as api from "../utils/api";
import {generatePath} from "react-router";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {buildClassNamesString} from "../utils/text";
import Loading from "../components/Loading";
import HomeButton from "../components/HomeButton";
import AppContext from "../contexts/AppContext";
import {debounce} from "../utils/etc";

const MAX_STEP = 3;

const FETCH_INTERVAL = 1000;
const HIGHTLIGHT_DELAY = 500;

const PHOTO_STATUS_FAILED = -1;
const PHOTO_STATUS_PROCESSED = 1;

const CREATIVE_STATUS_FAILED = -1;
const CREATIVE_STATUS_PROCESSED = 2;

const CREATIVE_GROUP_FIRST = "first";
const CREATIVE_GROUP_SECOND = "second";

const creativeIsFailed = (creative) => creative.status === CREATIVE_STATUS_FAILED;
const creativeIsProcessed = (creative) => creative.status === CREATIVE_STATUS_PROCESSED;
const creativeIsProcessing = (creative) => !(creativeIsProcessed(creative) || creativeIsFailed(creative));
const creativeInGroup = (group) => (creative) => creative.group === group;
const creativeExcludeWithIds = (ids) => (creative) => ids.indexOf(creative.id) === -1;

export default class CreatePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      result: null,
      selectIsDisabled: false,
      selectedCreatives: [-1, -1, -1],
      highlightedCreativeId: -1,
    };

    this.createPhoto = this.createPhoto.bind(this);
    this.fetchPhoto = this.fetchPhoto.bind(this);
    this.handlePhotoStatus = this.handlePhotoStatus.bind(this);
    this.handleCreativeSelect = this.handleCreativeSelect.bind(this);
    this.debugCreatives = this.debugCreatives.bind(this);
    this.handleWindowScrolled = this.handleWindowScrolled.bind(this);
    this.handleBack = this.handleBack.bind(this);

    this.fetchPhotoTimer = null;
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.createPhoto(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }

    window.addEventListener("scroll", this.handleWindowScrolled);
    window.pushOnBackPressed(() => this.handleBack("native"));
  }

  componentWillUnmount() {
    window.popOnBackPressed();
    window.removeEventListener("scroll", this.handleWindowScrolled);
    clearTimeout(this.fetchPhotoTimer);
  }

  handleWindowScrolled() {
    debounce("CreatePage_scroll_logEvent", 100, () => {
      logEvent(userEvents.COLLAGE_CREATIVES_SCROLL, {step: this.state.step});
    });
  }

  createPhoto(file) {
    api.createPhoto(file)
      .then((res) => {
        hitEvent(hits.PHOTO_UPLOADED);
        logEvent(userEvents.PHOTO_UPLOADED);

        this.handlePhotoStatus(res);
      })
      .catch((err) => {
        console.error(err);

        hitEvent(hits.PHOTO_UPLOAD_FAILED);
        logEvent(userEvents.PHOTO_UPLOAD_FAILED);

        this.props.history.replace(routes.ERROR);
      });
  }

  fetchPhoto() {
    api.fetchPhoto(this.state.result.photo.id)
      .then(this.handlePhotoStatus)
      .catch((err) => {
        console.error(err);

        this.props.history.replace(routes.ERROR);
      });
  }

  handlePhotoStatus(res) {
    if (res.photo.status === PHOTO_STATUS_FAILED) {
      hitEvent(hits.PROCESSING_FAILED);
      logEvent(userEvents.PROCESSING_FAILED);
      this.debugCreatives(res.creatives);

      if (res.photo.crop_task_response && res.photo.crop_task_response.description) {
        this.props.history.replace({
            pathname: routes.ERROR,
            state: {message: res.photo.crop_task_response.description}
        });
      } else {
        const failedCreative = res.creatives.find(creativeIsFailed);
        if (failedCreative) {
          this.props.history.replace({
            pathname: routes.ERROR,
            state: {message: failedCreative.error}
          });
        }
      }
    } else if (res.photo.status === PHOTO_STATUS_PROCESSED) {
      hitEvent(hits.PROCESSING_PROCESSED);
      logEvent(userEvents.PROCESSING_PROCESSED);
      this.debugCreatives(res.creatives);
    } else {
      this.fetchPhotoTimer = setTimeout(this.fetchPhoto, FETCH_INTERVAL);
    }

    this.setState({result: res});
  }

  debugCreatives(creatives) {
    if (!window.appConfig.isDebug) {
      return;
    }

    creatives
      .sort((c1, c2) => c1.status > c2.status ? -1 : (c1.status === c2.status ? 0 : 1))
      .forEach((creative) => {
        const isFailed = creativeIsFailed(creative);
        const c = {
          group: creative.group,
          name: creative.name,
          time: creative.processed_time,
        };

        if (isFailed) {
          c.error = creative.error;
        }

        console[isFailed ? "warn" : "info"](c);
      });
  }

  handleBack(type) {
    if (this.state.step === 1) {
      return false;
    }

    logEvent(userEvents.COLLAGE_STEP_BACK, {
      step: this.state.step,
      type
    });

    const selectedCreatives = this.state.selectedCreatives.slice();
    selectedCreatives[this.state.step - 2] = -1;

    this.setState({
      step: this.state.step - 1,
      selectedCreatives
    }, () => {
      window.scrollTo(0, 0);
    });

    return true;
  }

  handleCreativeSelect(creative) {
    if (this.state.selectIsDisabled) {
      return;
    }

    const selectedCreatives = this.state.selectedCreatives.slice();
    selectedCreatives[this.state.step - 1] = creative.id;

    logEvent(userEvents.COLLAGE_CREATIVE_SELECT, {
      step: this.state.step,
      name: creative.name,
    });

    const nextStep = this.state.step + 1;
    let cb;

    if (nextStep <= MAX_STEP) {
      cb = () => this.setState({
        selectIsDisabled: false,
        highlightedCreativeId: 0,
        step: nextStep,
        selectedCreatives,
      }, () => {
        window.scrollTo(0, 0);
      });
    } else {
      cb = () => api.createCollage(this.state.result.photo.id, selectedCreatives).then((res) => {
        this.props.history.replace({
          pathname: generatePath(routes.COLLAGE, {id: res.collage.id}),
          state: {photo: this.state.result.photo}
        });
      }).catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
    }

    this.setState({
      selectIsDisabled: true,
      highlightedCreativeId: creative.id
    }, () => {
      setTimeout(cb, HIGHTLIGHT_DELAY);
    });
  }

  render() {
    if (!this.state.result) {
      return <Loading />;
    }

    let creatives;

    if (this.state.step === 1) {
      creatives = this.state.result.creatives.filter(creativeInGroup(CREATIVE_GROUP_FIRST));
    } else {
      creatives = this.state.result.creatives
        .filter(creativeExcludeWithIds(this.state.selectedCreatives))
        .sort((left) => left.group === CREATIVE_GROUP_FIRST ? 1 : -1);
    }

    if (creatives.filter(creativeIsProcessing).length > 0) {
      return <Loading image={this.state.result.photo.file.url} />
    }


    const homeButtonProps = {
      page: "create",
    };

    if (this.state.step > 1) {
      homeButtonProps.onClick = () => this.handleBack("app");
      homeButtonProps.text = i18n.t("go_back");
    }

    const progressWidth = ((this.state.step - (this.state.highlightedCreativeId > 0 ? 0 : 1)) / MAX_STEP) * 100;

    return <main className="create-page">
      <div className="container">
        <div className="steps-progress">
          <span style={{width: `${progressWidth}%`}} />
        </div>
        <HomeButton {...homeButtonProps} />

        <h2>{i18n.t("create__step_headline_" + this.state.step)}</h2>
        <p className="create-steps">{i18n.t("create__step_counter", {current: this.state.step, total: MAX_STEP})}</p>

        <div className="creatives">
          {creatives.filter(creativeIsProcessed).map((creative) => <CreativeItem
            key={creative.id}
            creative={creative}
            isSelected={creative.id === this.state.highlightedCreativeId}
            onClick={() => this.handleCreativeSelect(creative)}
          />)}
        </div>
      </div>
    </main>
  }
}

CreatePage.contextType = AppContext;

function CreativeItem({creative, onClick, isSelected, number}) {
  const classNames = buildClassNamesString({
    "creative": true,
    "active": isSelected,
  });

  return <div onClick={onClick} className={classNames}>
    <div className="holder">
      <img src={creative.result_file_url} alt="Creative" />
      <div hidden={!number} className="num-check-foto">{number}</div>
    </div>
    <p>{creative.name}</p>
  </div>;
}