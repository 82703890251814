module.exports = {
  "strings": {
    "try_another_photo": "جرب صورة أخرى ",
    "go_back": "تراجع",

    "landing__title": "تحقق من مدى الاختلاف<br />يشاهدك جميع الفنانين",
    "landing__select_photo_button": "قم بتحميل صورة ما",

    "processing_text_0": "إبداع مذهل في طور الإنجاز...",
    "processing_text_1": "نحن نراهن على أن أصدقاءك سيعجبون بها 😍",
    "processing_text_2": "نحن تقريبًا انتهينا. <br />بضع ثوانٍ فقط وسوف تتفاجأ.",
    "processing_text_3": "هل لديك أية فكرة كيف ستبدو النتيجة؟",

    "create__select_one_result": "يُرجى تحديد نتيجة واحدة تفضلها كثيرًا وانقر 'التالي' للمتابعة",
    "create__select_two_results": "الآن حدِّد أفضل نتيجتين تفضلهما لإنشاء صورتك المجمَّعة",
    "create__next_button": "التالي",
    "create__create_collage_button": "إنشاء صورة مجمَّعة",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "حدث خطأ ما...",
  },
};

