module.exports = {
  "strings": {
    "try_another_photo": "Probar otra foto",
    "go_back": "Atrás",

    "landing__title": "Comprueba cómo te ven<br />diferentes artistas de IA",
    "landing__select_photo_button": "Subir una foto",

    "processing_text_0": "Algo increíble está pasando...",
    "processing_text_1": "Apostamos?<br />A tus amigos les va a encantar 😍",
    "processing_text_2": "Ya casi terminamos.<br />Solo unos pocos segundos y quedarás sorprendido.",
    "processing_text_3": "Tienes alguna idea de cuales<br />serán los resultados?",

    "create__select_one_result": "Por favor, selecciona el resultado que más te guste y pulsa en \"Siguiente\" para continuar",
    "create__select_two_results": "Ahora selecciona los dos resultados que más te gusten para crear tu collage",
    "create__next_button": "Siguiente",
    "create__create_collage_button": "Crear collage",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "Ocurrió un error...",
  },
};

