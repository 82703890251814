module.exports = {
  "strings": {
    "try_another_photo": "Ein anderes Foto ausprobieren",
    "go_back": "Zurück",

    "landing__title": "Überprüfe, wie verschiedene<br />KI-Künstler dich sehen",
    "landing__select_photo_button": "Lade ein Foto hoch",

    "processing_text_0": "Etwas Sagenhaftes kommt bald…",
    "processing_text_1": "Wir wetteten,<br />Ihre Freunde mögen diese 😍",
    "processing_text_2": "Alles ist fast fertig.<br />Einen Augenblick, und Sie werden überrascht.",
    "processing_text_3": "Wissen Sie,<br />wie das Ergebnis aussehen werden?",

    "create__select_one_result": "Bitte wähle ein Ergebnis aus, das dir am besten gefällt, und tippe auf ‚Weiter‘, um fortzufahren",
    "create__select_two_results": "Wähle nun zwei Ergebnisse aus, die dir am besten gefallen, um deine Collage zu erstellen",
    "create__next_button": "Weiter",
    "create__create_collage_button": "Collage erstellen",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "Ein Fehler ist aufgetreten...",
  },
};

