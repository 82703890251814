import React from "react";
import ReactDOM from "react-dom";
import {Route, Switch, withRouter} from "react-router";
import {BrowserRouter} from "react-router-dom";
import routes from "./routes";
import IndexPage from "./pages/IndexPage";
import ErrorPage from "./pages/ErrorPage";
import CreatePage from "./pages/CreatePage";
import CollagePage from "./pages/CollagePage";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {hitEvent, hits} from "./utils/log";
import * as webviewUtils from "./utils/webview";

class App extends React.Component {

  componentDidMount() {
    window.webviewHolders.tabSelected.subscribe((v) => {
      if (v > 0) {
        hitEvent(hits.TAB_OPENED);
      }
    })

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      })
    }
  }

  render() {
    return (
      <div className={`${window.clientConfig.locale}`}>
        <Switch>
          <Route exact path={routes.INDEX} render={props => <IndexPage {...props} />}/>
          <Route exact path={routes.ERROR} render={props => <ErrorPage {...props} />}/>
          <Route exact path={routes.CREATE} render={props => <CreatePage {...props} />}/>
          <Route exact path={routes.COLLAGE} render={props => <CollagePage {...props} />}/>
        </Switch>
        <ModalWrapper/>
      </div>
    );
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
