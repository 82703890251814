import React from 'react';
import i18n from "../i18n";
import FileChooseButton from "../components/FileChooseButton";
import routes from "../routes";
import Slider from "react-slick";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";

export default class IndexPage extends React.Component {

  constructor(props) {
    super(props);

    this.handleFileSelected = this.handleFileSelected.bind(this);
  }

  componentDidMount() {
    
  }

  handleFileSelected(file) {
    logEvent(userEvents.PHOTO_SELECT, {page: "index"});
    hitEvent(hits.PHOTO_SELECT);

    this.props.history.push(routes.CREATE, {file});
  }

  render() {
    const sliderSettings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return <main className="main-page">
      <div className="container">
        <h1 className="main-title" dangerouslySetInnerHTML={{__html: i18n.t("landing__title")}} />


        <section className="wr-slider">
          <Slider {...sliderSettings}>
            <picture>
              <source srcSet="./assets/img/slider/img-1.webp?1 1x, ./assets/img/slider/img-1@2x.webp?1 2x" type="image/webp" />
              <img srcSet="./assets/img/slider/img-1@2x.png?1 2x" src="./assets/img/slider/img-1.png?1" alt="Demo" />
            </picture>
            <picture>
              <source srcSet="./assets/img/slider/img-2.webp?1 1x, ./assets/img/slider/img-2@2x.webp?1 2x" type="image/webp" />
              <img srcSet="./assets/img/slider/img-2@2x.png?1 2x" src="./assets/img/slider/img-2.png?1" alt="Demo" />
            </picture>
            <picture>
              <source srcSet="./assets/img/slider/img-3.webp?1 1x, ./assets/img/slider/img-3@2x.webp?1 2x" type="image/webp" />
              <img srcSet="./assets/img/slider/img-3@2x.png?1 2x" src="./assets/img/slider/img-3.png?1" alt="Demo" />
            </picture>
            
          {/* <img  
              srcset="./assets/img/slider/img-1@2x.png 2x" 
              src="./assets/img/slider/img-1.png" 
              alt="slide image"/>
            <img  
              srcset="./assets/img/slider/img-2@2x.png 2x" 
              src="./assets/img/slider/img-2.png" 
              alt="slide image"/>
            <img  
              srcset="./assets/img/slider/img-3@2x.png 2x" 
              src="./assets/img/slider/img-3.png" 
              alt="slide image"/> */}
            {/* <div>
              <div className="slide">
                <div className="slide-item">
                  <h2>Original</h2>
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-1@2x.jpg 2x, ./assets/img/slider/slide1/slide1-1@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-1.jpg" 
                      alt="slide image"/>
                  </div>
                </div>
                <div className="slide-item">
                  <h2>New Dawn</h2>
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-2@2x.jpg 2x, ./assets/img/slider/slide1/slide1-2@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-2.jpg" 
                      alt="slide image"/>
                  </div>
                </div>
                <div className="slide-item">
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-3@2x.jpg 2x, ./assets/img/slider/slide1/slide1-3@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-3.jpg" 
                      alt="slide image"/>
                  </div>
                  <h2>Honey Toon</h2>
                </div>
                <div className="slide-item slide-item-active">
                  <h2>Toon Hot To Handle</h2>
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-4@2x.jpg 2x, ./assets/img/slider/slide1/slide1-4@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-4.jpg" 
                      alt="slide image"/>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="slide">
                <div className="slide-item">
                  <h2>Original</h2>
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-1@2x.jpg 2x, ./assets/img/slider/slide1/slide1-1@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-1.jpg" 
                      alt="slide image"/>
                  </div>
                </div>
                <div className="slide-item">
                  <h2>New Dawn</h2>
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-2@2x.jpg 2x, ./assets/img/slider/slide1/slide1-2@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-2.jpg" 
                      alt="slide image"/>
                  </div>
                </div>
                <div className="slide-item">
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-3@2x.jpg 2x, ./assets/img/slider/slide1/slide1-3@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-3.jpg" 
                      alt="slide image"/>
                  </div>
                  <h2>Honey Toon</h2>
                </div>
                <div className="slide-item">
                  <h2>Toon Hot To Handle</h2>
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-4@2x.jpg 2x, ./assets/img/slider/slide1/slide1-4@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-4.jpg" 
                      alt="slide image"/>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="slide">
                <div className="slide-item">
                  <h2>Original</h2>
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-1@2x.jpg 2x, ./assets/img/slider/slide1/slide1-1@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-1.jpg" 
                      alt="slide image"/>
                  </div>
                </div>
                <div className="slide-item">
                  <h2>New Dawn</h2>
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-2@2x.jpg 2x, ./assets/img/slider/slide1/slide1-2@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-2.jpg" 
                      alt="slide image"/>
                  </div>
                </div>
                <div className="slide-item">
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-3@2x.jpg 2x, ./assets/img/slider/slide1/slide1-3@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-3.jpg" 
                      alt="slide image"/>
                  </div>
                  <h2>Honey Toon</h2>
                </div>
                <div className="slide-item">
                  <h2>Toon Hot To Handle</h2>
                  <div className="slide-image-container">
                    <img  
                      srcset="./assets/img/slider/slide1/slide1-4@2x.jpg 2x, ./assets/img/slider/slide1/slide1-4@3x.jpg 3x" 
                      src="./assets/img/slider/slide1/slide1-4.jpg" 
                      alt="slide image"/>
                  </div>
                </div>
              </div>
            </div> */}
          </Slider>
        </section>

        <FileChooseButton
          onFileSelected={this.handleFileSelected}
          className="btn-upload-foto"
          children={i18n.t("landing__select_photo_button")} />
      </div>
    </main>;
  }
}

IndexPage.contextType = AppContext;