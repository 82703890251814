module.exports = {
  "strings": {
    "try_another_photo": "다른 사진으로 만들기",
    "go_back": "돌아가기",

    "landing__title": "인공 지능 화가가 귀하의 모습을<br />어떻게 그리는지 확인해 보세요",
    "landing__select_photo_button": "사진 업로드",

    "processing_text_0": "믿을 수 없는 일이 일어나고 있어요…",
    "processing_text_1": "친구들이 분명히 좋아할 거예요 😍",
    "processing_text_2": "거의 다 되었어요.<br />단 몇 초 후 놀라게 될거예요.",
    "processing_text_3": "결과가 어떨지 상상이 되세요?",

    "create__select_one_result": "가장 마음에 드는 결과를 선택한 후 '다음'을 탭하여 진행하세요",
    "create__select_two_results": "마음에 드는 결과 2개를 선택하여 콜라주를 만들어 보세요",
    "create__next_button": "다음",
    "create__create_collage_button": "콜라주 만들기",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "오류 발생...",
  },
};

