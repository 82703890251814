import * as amplitude from "amplitude-js";

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
}

// --

export const userEvents = {
  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create1, create2, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  // юзер выбрал фото
  // параметры: page (страница: index, error)
  PHOTO_SELECT: "photo_select",

  // юзер успешно загрузил фото
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  // успешно закончился процессинг всех обработок
  PROCESSING_PROCESSED: "processing_processed",

  // неуспешно закончился процессинг всех обработок
  PROCESSING_FAILED: "processing_failed",

  // юзер проскроллил страницу на этапе выбора обработок для коллажа
  // параметры: step (шаг)
  COLLAGE_CREATIVES_SCROLL: "collage_creatives_scroll",

  // юзер выбрал (только кликнул) обработку для коллажа
  // параметры: step (шаг), name (название шаблона обработки)
  COLLAGE_CREATIVE_SELECT: "collage_creative_select",

  // юзер нажал назад на странице выбора обработок
  // параметры: step (на каком шаге юзер был до возврата), type (тип: app, native)
  COLLAGE_STEP_BACK: "collage_step_back",

  // юзер нажал кнопку продолжить (deprecated)
  // параметры: step (шаг), selected (кол-во выбранных обработок во время клика)
  COLLAGE_NEXT_BUTTON_CLICK: "collage_next_button_click",

  // юзер подтвердил выбор обработок из первого шага (deprecated)
  COLLAGE_SELECT_FIRST_GROUP: "collage_select_first_group",

  // юзер подтвердил выбор обработок из второго шага (deprecated)
  COLLAGE_SELECT_SECOND_GROUP: "collage_select_second_group",

  // коллаж успешно сгенерировался
  COLLAGE_PROCESSED: "collage_processed",

  // коллаж не сгенерировался
  COLLAGE_FAILED: "collage_failed",

  // юзер кликнул на кнопку шаринга
  // параметры: id (наш идентификатор коллажа), provider (название поток шаринга)
  COLLAGE_SHARE: "collage_share",
};

export const hits = {
  TAB_OPENED: 8091,

  PHOTO_SELECT: 8058,
  PHOTO_UPLOADED: 8059,
  PHOTO_UPLOAD_FAILED: 8060,

  PROCESSING_PROCESSED: 8061,
  PROCESSING_FAILED: 8062,

  COLLAGE_SELECT_FIRST_GROUP: 8065,
  COLLAGE_SELECT_SECOND_GROUP: 8066,

  COLLAGE_PROCESSED: 8063,
  COLLAGE_FAILED: 8064,

  COLLAGE_SHARE: 8107,
};

export function hitEvent(id) {
  if (window.appConfig.isDebug) {
    console.info("hitEvent", id);
  }

  if (window.appConfig.hits.isEnabled) {
    window.axios.post(`${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`)
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logEvent(eventId, eventParams, cb) {
  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  logAmplitude(eventId, eventParams, cb);

  if (window.appConfig.analytics.isEnabled) {
    window.axios.post(window.appConfig.analytics.endpoint, {id: eventId, params: eventParams})
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logAmplitude(eventId, eventParams, cb) {
  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }
}