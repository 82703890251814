module.exports = {
  "strings": {
    "try_another_photo": "Essayer une autre photo",
    "go_back": "Retour",

    "landing__title": "Découvrez l’image singulièreque<br />des IA artistes ont de vous",
    "landing__select_photo_button": "Télécharger une photo",

    "processing_text_0": "Quelque chose d'incroyable se prépare...",
    "processing_text_1": "On parie que vos amis<br />vont adorer 😍",
    "processing_text_2": "Nous avons presque terminé.<br />Encore quelques secondes et vous allez être étonnés.",
    "processing_text_3": "Avez-vous la moindre idée du résultat<br />que vous allez obtenir ?",

    "create__select_one_result": "Veuillez sélectionner un des résultats que vous aimez le plus, puis tapez sur «Suivant» pour continuer",
    "create__select_two_results": "Maintenant, sélectionnez deux des résultats que vous aimez le plus pour créer votre collage",
    "create__next_button": "Suivant",
    "create__create_collage_button": "Créer le collage",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "Une erreur est survenue...",
  },
};

