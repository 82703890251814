module.exports = {
  "strings": {
    "try_another_photo": "Prova un’altra foto",
    "go_back": "Ritornare",

    "landing__title": "Guarda in che modo appari<br />ai vari artisti IA",
    "landing__select_photo_button": "Carica una foto",

    "processing_text_0": "Sta arrivando qualcosa di incredibile ...",
    "processing_text_1": "Scommettiamo<br />che tuoi amici piaceranno 😍",
    "processing_text_2": "Abbiamo cosi finito.<br />In pochi secondi e sarete sorpresi.",
    "processing_text_3": "Hai idea di come<br />sarà il risultato?",

    "create__select_one_result": "Seleziona un risultato che ti piace maggiormente, dopodiché tocca \"Prossimo\" per continuare",
    "create__select_two_results": "Seleziona ora due risultati che ti piacciono maggiormente per creare il tuo collage",
    "create__next_button": "Prossimo",
    "create__create_collage_button": "Crea collage",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "Si è verificato un errore...",
  },
};

