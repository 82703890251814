import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import * as api from "../utils/api";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import Loading from "../components/Loading";
import HomeButton from "../components/HomeButton";
import Share from "../components/Share";
import AppContext from "../contexts/AppContext";

const COLLAGE_STATUS_FAILED = -1;
const COLLAGE_STATUS_PROCESSED = 1;

const FETCH_INTERVAL = 1000;

export default class CollagePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      collage: null,
      isAuthor: false,
      isGuest: true,
    };

    this.fetchCollage = this.fetchCollage.bind(this);
    this.handleCollage = this.handleCollage.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.collage) {
      this.handleCollage(this.props.location.state.collage);
    } else {
      this.fetchCollage();
    }
  }

  fetchCollage() {
    api.fetchCollage(this.props.match.params.id).then((res) => {
      this.setState({
        isAuthor: res.is_author,
        isGuest: !res.is_author,
      });

      this.handleCollage(res.collage);
    }).catch((err) => {
      console.error(err);
      this.props.history.replace(routes.ERROR);
    });
  }

  handleCollage(collage) {
    let isLoading = false;
    let cb = undefined;

    if (collage.status === COLLAGE_STATUS_FAILED) {
      hitEvent(hits.COLLAGE_FAILED);
      logEvent(userEvents.COLLAGE_FAILED);

      this.props.history.replace(routes.ERROR);
      return;
    }

    if (collage.status === COLLAGE_STATUS_PROCESSED) {
      hitEvent(hits.COLLAGE_PROCESSED);
      logEvent(userEvents.COLLAGE_PROCESSED);
    } else {
      isLoading = true;
      cb = () => {
        setTimeout(this.fetchCollage, FETCH_INTERVAL);
      };
    }

    this.setState({isLoading, collage}, cb);
  }

  render() {
    if (this.state.isLoading) {
      const photo = this.props.location.state
        && this.props.location.state.photo
        && this.props.location.state.photo.file
        && this.props.location.state.photo.file.url;

      return <Loading image={photo} />
    }

    return <main className="collage-page">
      <div className="container">
        <HomeButton page="result" />
        <h2>{i18n.t("collage__title")}</h2>

        <img src={this.state.collage.file.url} alt="Collage" />

        <Share
          isHidden={!this.state.collage || this.state.isGuest || !window.clientConfig.isWebview}
          collage={this.state.collage}
        />
      </div>
    </main>;
  }
}

CollagePage.contextType = AppContext;