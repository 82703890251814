module.exports = {
  "strings": {
    "try_another_photo": "कोई और फोटो आज़माएं",
    "go_back": "पीछे जाएं",

    "landing__title": "देखें कि कैसे अलग-अलग<br />AI कलाकार आपको देखते हैं",
    "landing__select_photo_button": "फोटो अपलोड करें",

    "processing_text_0": "कुछ अविश्वसनीय आने वाला है...",
    "processing_text_1": "हम शर्त लगाते हैं, आपके दोस्त इसे पसंद करेंगे 😍",
    "processing_text_2": "हमारा काम लगभग पूरा हो चुका है।<br />बस कुछ सेकंड और आप चौंक जाएंगे।",
    "processing_text_3": "आपको कोई अंदाजा है कि क्या परिणाम दिखने वाला है?",

    "create__select_one_result": "कृपया एक परिणाम, जिसे आप सबसे अधिक पसंद करते हैं, चुनें और आगे बढ़ने के लिए 'अगला' पर टैप करें",
    "create__select_two_results": "अब अपने कोलाज बनाने के लिए दो परिणामों का चयन करें, जिन्हें आप सबसे अधिक पसंद करते हैं",
    "create__next_button": "अगला",
    "create__create_collage_button": "कोलाज बनाएं",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "एक एरर पाया गया",
  },
};

