module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",
    "go_back": "Go Back",

    "landing__title": "Check how different<br />AI artists see you",
    "landing__select_photo_button": "Upload a photo",

    "processing_text_0": "Something unbelievable is on its way...",
    "processing_text_1": "We bet,<br />your friends are gonna like it 😍",
    "processing_text_2": "We’re almost done.<br />Just a few seconds and you'll be surprised.",
    "processing_text_3": "D’you have any idea<br />what the result is gonna look like?",

    "create__step_headline_1": "Tap the result you like most to proceed",
    "create__step_headline_2": "Select one more result...",
    "create__step_headline_3": "And the last one!",
    "create__step_counter": "{{current}} out of {{total}}",

    "create__select_one_result": "Please select the result you like most and tap 'Next' to proceed",
    "create__select_two_results": "Now select two results you like best to create your collage",
    "create__next_button": "Next",
    "create__create_collage_button": "Create collage",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "An error occurred...",
  },
};

