import i18n from "i18next";

import arStrings from "./languages/ar";
import deStrings from "./languages/de";
import enStrings from "./languages/en";
import esStrings from "./languages/es";
import frStrings from "./languages/fr";
import hiStrings from "./languages/hi";
import itStrings from "./languages/it";
import jaStrings from "./languages/ja";
import koStrings from "./languages/ko";
import ptBrStrings from "./languages/pt-br";
import ruStrings from "./languages/ru";
import thStrings from "./languages/th";
import zhStrings from "./languages/zh";

i18n.init({
  resources: {
    ar: arStrings,
    de: deStrings,
    en: enStrings,
    es: esStrings,
    fr: frStrings,
    hi: hiStrings,
    it: itStrings,
    ja: jaStrings,
    ko: koStrings,
    "pt-BR": ptBrStrings,
    ru: ruStrings,
    th: thStrings,
    zh: zhStrings,
  },
  lng: window.clientConfig.locale,
  fallbackLng: "en",
  debug: window.appConfig.isDebug,
  ns: ["strings"],
  defaultNS: "strings",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
}, () => {
  window.axios.defaults.headers.common["X-Locale"] = i18n.language;
});

export default i18n;
