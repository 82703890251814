import React from "react";
import { generatePath } from "react-router";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import * as webviewUtils from "../utils/webview";
import * as api from "../utils/api"

export default class Share extends React.Component {

  constructor(props) {
    super(props);

    this.url = window.appConfig.paths.app + generatePath(routes.COLLAGE, { id: this.props.collage.id });

    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handleShareToFacebookButtonClick = this.handleShareToFacebookButtonClick.bind(this);
    this.handleShareToSnapchatButtonClick = this.handleShareToSnapchatButtonClick.bind(this);
    this.handleShareToInstagramButtonClick = this.handleShareToInstagramButtonClick.bind(this);
    this.handleShareToWhatsAppButtonClick = this.handleShareToWhatsAppButtonClick.bind(this);
    this.handleShareToFacebookMessengerButtonClick = this.handleShareToFacebookMessengerButtonClick.bind(this);
    this.handleShareToEmailButtonClick = this.handleShareToEmailButtonClick.bind(this);
    this.handleShareToTelegramButtonClick = this.handleShareToTelegramButtonClick.bind(this);
    this.handleShareToSmsButtonClick = this.handleShareToSmsButtonClick.bind(this);
    this.handleShareToNativeButtonClick = this.handleShareToNativeButtonClick.bind(this);
    this.logShare = this.logShare.bind(this);
  }

  logShare(provider) {
    hitEvent(hits.COLLAGE_SHARE);
    logEvent(userEvents.COLLAGE_SHARE, {
      id: this.props.collage.id,
      provider
    });

    api.logCollageShare(this.props.collage.id, provider);
  }

  handleDownloadClick() {
    this.logShare("download");

    webviewUtils.webviewShareDownload(this.props.collage.file.url);
  }

  handleShareToFacebookButtonClick() {
    this.logShare("facebook");

    webviewUtils.webviewShareFacebook(null, this.props.collage.file.url);
  }

  handleShareToSnapchatButtonClick() {
    this.logShare("snapchat");

    const sw = window.clientConfig.isWebviewIOS
      ? window.screen.width
      : Math.floor(window.screen.width * (window.devicePixelRatio || 1));

    const params = Object.assign({
      providers: "[11]",
      caption_text: "",
      sticker_url: encodeURIComponent(this.props.collage.file.url),
      sc_sticker_posx: 0.5,
      sc_sticker_posy: 0.5,
      sc_sticker_width: Math.floor(sw * 0.7),
      sc_sticker_height: Math.floor(sw * 0.7),
      sc_sticker_rotation: 0.0,
    });

    webviewUtils.webviewShare(params);    
  }

  handleShareToInstagramButtonClick() {
    this.logShare("instagram");

    const params = Object.assign({
      providers: "[1]",
      imageUrl: encodeURIComponent(this.props.collage.file.url),
      ig_direct: 1,
      close_after_share: 1
    });

    webviewUtils.webviewShare(params);
  }

  handleShareToWhatsAppButtonClick() {
    this.logShare("whatsapp");

    const params = Object.assign({
      providers: "[3]",
      //url: encodeURIComponent(this.props.collage.file.url),
      imageUrl: encodeURIComponent(this.props.collage.file.url),
    });

    webviewUtils.webviewShare(params);
  }

  handleShareToFacebookMessengerButtonClick() {
    this.logShare("facebookmessenger");

    const params = Object.assign({
      providers: "[5]",
      //url: encodeURIComponent(this.props.collage.file.url),
      imageUrl: encodeURIComponent(this.props.collage.file.url),
    });

    webviewUtils.webviewShare(params);
  }

  handleShareToEmailButtonClick() {
    this.logShare("email");

    const params = Object.assign({
      providers: "[7]",
      imageUrl: encodeURIComponent(this.props.collage.file.url),
    });

    webviewUtils.webviewShare(params);
  }

  handleShareToTelegramButtonClick() {
    this.logShare("telegram");

    const params = Object.assign({
      providers: "[6]",
      //url: encodeURIComponent(this.props.collage.file.url),
      imageUrl: encodeURIComponent(this.props.collage.file.url),
    });

    webviewUtils.webviewShare(params);
  }

  handleShareToSmsButtonClick() {
    this.logShare("sms");

    const params = Object.assign({
      providers: "[8]",
      url: encodeURIComponent(this.props.collage.file.url),
    });

    webviewUtils.webviewShare(params);
  }

  handleShareToNativeButtonClick() {
    this.logShare("native_share");

    // todo вызываем нативный шаринг через телеграмм для иос
    const params = Object.assign({
      providers: "[6]",
      url: encodeURIComponent(this.props.collage.file.url),
      imageUrl: encodeURIComponent(this.props.collage.file.url),
    });

    webviewUtils.webviewShare(params);
  }

  render() {
    if (this.props.isHidden) {
      return null;
    }

    return <div className="share-container">
      <button onClick={this.handleDownloadClick} hidden={false}>
        <SvgDownloadIcon />
      </button>
      <button onClick={this.handleShareToFacebookButtonClick} hidden={!this.context.installedApps.facebook}>
        <SvgFacebookIcon />
      </button>
      <button onClick={this.handleShareToSnapchatButtonClick} hidden={!this.context.installedApps.snapchat}>
        <SvgSnapchatIcon />
      </button>
      <button onClick={this.handleShareToInstagramButtonClick} hidden={!this.context.installedApps.instagram}>
        <SvgInstagramIcon />
      </button>
      <button onClick={this.handleShareToWhatsAppButtonClick} hidden={!this.context.installedApps.whatsapp || window.clientConfig.isWebviewIOS}>
        <SvgWhatsAppIcon />
      </button>
      <button onClick={this.handleShareToFacebookMessengerButtonClick} hidden={!this.context.installedApps.facebookm || window.clientConfig.isWebviewIOS}>
        <SvgFacebookMessengerIcon />
      </button>
      <button onClick={this.handleShareToEmailButtonClick} hidden={false}>
        <SvgEmailIcon />
      </button>
      <button onClick={this.handleShareToTelegramButtonClick} hidden={!this.context.installedApps.telegram || window.clientConfig.isWebviewIOS}>
        <SvgTelegramIcon />
      </button>
      <button onClick={this.handleShareToSmsButtonClick} hidden={false}>
        <SvgSmsIcon />
      </button>
      <button onClick={this.handleShareToNativeButtonClick} hidden={!window.clientConfig.isWebviewIOS}>
        <SvgNativeShareIcon />
      </button>
    </div>;
  }
}

Share.contextType = AppContext;

function SvgDownloadIcon() {
  return <svg viewBox="0 0 120 120">
    <g fill="none" fillRule="evenodd">
      <g fill="#05B0FE">
        <g>
          <g>
            <path d="M60 0c33.137 0 60 26.863 60 60s-26.863 60-60 60S0 93.137 0 60 26.863 0 60 0zM48.889 37.5L26.667 60l22.222 22.5 3.889-3.938-15.556-15.75h56.111v-5.624h-56.11l15.555-15.75-3.89-3.938z" transform="translate(-72 -2041) translate(0 2005) rotate(-90 114 42)" />
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgFacebookIcon() {
  return <svg viewBox="0 0 120 120">
    <g fill="none" fillRule="evenodd">
      <g fill="#1877F2" fillRule="nonzero">
        <g>
          <g>
            <path d="M59.878.25C26.808.25 0 27.057 0 60.126c0 29.66 21.587 54.222 49.891 58.979V72.619H35.447V55.891h14.444V43.556c0-14.312 8.742-22.112 21.511-22.112 6.116 0 11.372.456 12.897.657v14.96l-8.856.004c-6.943 0-8.282 3.299-8.282 8.14v10.677h16.567l-2.16 16.729H67.16v46.895c29.626-3.606 52.594-28.795 52.594-59.396 0-33.052-26.809-59.86-59.877-59.86z" transform="translate(-264 -2041) translate(0 2005) translate(264 36)" />
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgInstagramIcon() {
  return <svg viewBox="0 0 120 120">
    <defs>
      <linearGradient id="by22orgw5b" x1="6.731%" x2="93.591%" y1="93.485%" y2="6.619%">
        <stop offset="0%" stopColor="#FD5" />
        <stop offset="50%" stopColor="#FF543E" />
        <stop offset="100%" stopColor="#C837AB" />
      </linearGradient>
      <circle id="uwpflrr22a" cx="60" cy="60" r="60" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-456 -1908) translate(0 1872) translate(456 36)">
              <mask id="jzkotng65c" fill="#fff">
                <use xlinkHref="#uwpflrr22a"/>
              </mask>
              <use fill="#D8D8D8" xlinkHref="#uwpflrr22a"/>
              <path fill="url(#by22orgw5b)" fillRule="nonzero" d="M8 8.428C-1.375 18.166.544 28.51.544 59.936c0 26.097-4.554 52.26 19.277 58.418 7.441 1.914 73.375 1.914 80.806-.01 9.922-2.56 17.995-10.608 19.098-24.64.154-1.96.154-65.542-.005-67.54C118.547 11.217 109.346 2.602 97.222.857 94.443.455 93.887.336 79.63.311 29.062.336 17.977-1.916 8 8.428z" mask="url(#jzkotng65c)" />
            </g>
            <path fill="#FFF" fillRule="nonzero" d="M60 24.035c-14.745 0-28.747-1.311-34.095 12.405-2.21 5.664-1.889 13.021-1.889 23.555 0 9.244-.296 17.932 1.889 23.552 5.336 13.724 19.452 12.41 34.088 12.41 14.12 0 28.679 1.468 34.092-12.41 2.213-5.721 1.888-12.969 1.888-23.552 0-14.048.776-23.117-6.042-29.926-6.904-6.899-16.24-6.034-29.946-6.034H60zm-3.224 6.48c30.759-.049 34.673-3.465 32.513 44-.768 16.787-13.56 14.945-29.284 14.945-28.67 0-29.495-.82-29.495-29.48 0-28.994 2.274-29.449 26.266-29.473v.008zm22.433 5.97c-2.383 0-4.316 1.93-4.316 4.313 0 2.382 1.933 4.313 4.316 4.313 2.384 0 4.317-1.931 4.317-4.313 0-2.382-1.933-4.314-4.317-4.314zm-19.208 5.043c-10.205 0-18.478 8.27-18.478 18.467 0 10.198 8.273 18.464 18.478 18.464 10.205 0 18.473-8.266 18.473-18.464 0-10.197-8.268-18.467-18.473-18.467zm0 6.48c15.858 0 15.878 23.974 0 23.974-15.854 0-15.879-23.973 0-23.973z" transform="translate(-456 -1908) translate(0 1872) translate(456 36)" />
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgWhatsAppIcon() {
  return <svg viewBox="0 0 120 120">
    <g fill="none" fillRule="evenodd">
      <g fill="#00CE3F">
        <g>
          <g>
            <path d="M60 0c33.137 0 60 26.863 60 60s-26.863 60-60 60S0 93.137 0 60 26.863 0 60 0zm.117 26.667c-18.284 0-33.165 14.875-33.172 33.159-.003 5.844 1.525 11.55 4.428 16.578l-4.706 17.184 17.585-4.61c4.845 2.64 10.3 4.033 15.852 4.035h.013c18.282 0 33.164-14.877 33.172-33.16.003-8.861-3.444-17.193-9.707-23.461-6.262-6.268-14.591-9.722-23.465-9.725zm.011 5.6c7.364.003 14.287 2.874 19.492 8.084 5.206 5.21 8.071 12.134 8.068 19.5-.006 15.197-12.374 27.562-27.57 27.562h-.012c-4.947-.002-9.8-1.33-14.033-3.842l-1.007-.598-10.435 2.737 2.786-10.171-.656-1.043c-2.76-4.388-4.218-9.46-4.215-14.668.006-15.197 12.374-27.56 27.582-27.56zM48.373 44.512c-.553 0-1.45.208-2.21 1.037-.76.83-2.901 2.834-2.901 6.912 0 4.079 2.97 8.019 3.384 8.572.415.553 5.845 8.922 14.16 12.511 1.977.854 3.52 1.364 4.724 1.746 1.986.63 3.793.541 5.221.328 1.592-.238 4.904-2.005 5.595-3.94.69-1.936.69-3.595.483-3.94-.207-.346-.76-.553-1.588-.968-.83-.415-4.904-2.42-5.664-2.696-.76-.276-1.312-.415-1.865.415-.552.83-2.14 2.696-2.625 3.249-.483.553-.966.622-1.795.207-.83-.415-3.5-1.29-6.666-4.112-2.464-2.197-4.127-4.911-4.61-5.74-.484-.83-.052-1.278.363-1.691.373-.372.829-.968 1.243-1.452.414-.484.553-.83.829-1.383.276-.553.138-1.036-.07-1.451-.206-.415-1.864-4.493-2.555-6.152-.538-1.293-1.083-1.411-1.54-1.42h-.263l-.062-.003c-.482-.024-1.036-.029-1.588-.029z" transform="translate(-648 -2041) translate(0 2005) translate(648 36)" />
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgFacebookMessengerIcon() {
  return <svg viewBox="0 0 120 120">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-840 -2041) translate(0 2005) translate(840 36)">
            <circle cx="60" cy="60" r="60" fill="#2196F3" />
            <path fill="#FFF" fillRule="nonzero" d="M28 58.63c0 9.324 4.65 17.641 11.923 23.074v11.299l10.897-5.98c2.906.803 5.987 1.24 9.179 1.24C77.67 88.264 92 75 92 58.634 92.003 42.267 77.674 29 60.001 29 42.33 29 28 42.265 28 58.63zm28.625-8.298l8.347 8.69 15.7-8.69-17.495 18.566-8.147-8.689L39.128 68.9l17.497-18.568z" />
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgEmailIcon() {
  return <svg viewBox="0 0 120 120">
    <g fill="none" fillRule="evenodd">
      <g fill="#6CB0DC">
        <g>
          <g>
            <path d="M60 0c33.137 0 60 26.863 60 60s-26.863 60-60 60S0 93.137 0 60 26.863 0 60 0zm26.667 33.333H33.333c-3.666 0-6.633 3-6.633 6.667l-.033 40c0 3.667 3 6.667 6.666 6.667h53.334c3.666 0 6.666-3 6.666-6.667V40c0-3.667-3-6.667-6.666-6.667zm0 13.334V80H33.333V46.667L60 63.333l26.667-16.666zm0-6.667L60 56.667 33.333 40h53.334z" transform="translate(-1032 -2041) translate(0 2005) translate(1032 36)" />
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgTelegramIcon() {
  return <svg viewBox="0 0 120 120">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g transform="translate(-667 -1908) translate(-101 1872) translate(768 36)">
            <circle cx="60" cy="60" r="60" fill="#039BE5" />
            <path fill="#FFF" d="M27.455 58.7l57.85-22.305c2.685-.97 5.03.655 4.16 4.715l.005-.005-9.85 46.405c-.73 3.29-2.685 4.09-5.42 2.54l-15-11.055-7.235 6.97c-.8.8-1.475 1.475-3.025 1.475l1.065-15.265 27.8-25.115c1.21-1.065-.27-1.665-1.865-.605l-34.355 21.63-14.81-4.62c-3.215-1.02-3.285-3.215.68-4.765z" />
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgSmsIcon() {
  return <svg viewBox="0 0 120 120">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-858 -2041) translate(-102 2005) translate(960 36)">
            <circle cx="60" cy="60" r="60" fill="#6DDB5F" />
            <path fill="#FFF" fillRule="nonzero" d="M37.318 88.35h-4.535l3.207-3.207c1.729-1.729 2.809-3.978 3.099-6.417C31.685 73.867 28 66.828 28 59.449 28 45.835 40.512 32 60.108 32 80.868 32 92 44.73 92 58.256c0 13.615-11.25 26.337-31.892 26.337-3.616 0-7.389-.483-10.758-1.369-3.189 3.271-7.51 5.125-12.032 5.125z" />
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgNativeShareIcon() {
  return <svg viewBox="0 0 120 120">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-1051 -1908) translate(-101 1872) translate(1152 36)">
            <circle cx="60" cy="60" r="60" fill="#DCDDDC" />
            <circle cx="36" cy="60" r="8" fill="#ACAAAC" />
            <circle cx="60" cy="60" r="8" fill="#ACAAAC" />
            <circle cx="84" cy="60" r="8" fill="#ACAAAC" />
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function SvgSnapchatIcon() {
  return <svg viewBox="0 0 120 120">
    <defs>
      <circle id="cdvw14eu6a" cx="60" cy="60" r="60" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-648 -1908) translate(0 1872) translate(648 36)">
            <mask id="3vps3zohqb" fill="#fff">
              <use xlinkHref="#cdvw14eu6a"/>
            </mask>
            <use fill="#FFEB3B" xlinkHref="#cdvw14eu6a"/>
            <g fillRule="nonzero" mask="url(#3vps3zohqb)">
              <g>
                <path fill="#FFF" d="M61.003 56.882c2.931-.47 9.052-1.578 9.222-4.533.048-.831-.532-1.57-1.345-1.7v-.004C59.834 49.127 54.14 38.713 55.017 36.63c.634-1.506 3.46-2.089 4.707-2.579 5.965-2.39 3.395-6.481-.129-6.481-1.586 0-3.47 1.72-5.205.911.538-9.16 1.494-15.611-4.985-21.85-8.409-8.087-25.472-7.146-31.294 6.14-1.73 3.942-1.066 11.147-.788 15.71-1.226.547-2.977-.042-4.187-.622-3.051-1.452-8.269 3.32-1.139 6.19 3.09 1.233 6.013 1.586 4.381 4.963-.176.323-1.168 2.785-3.496 5.54-6.712 7.952-12.826 4.7-11.218 8.511v.006c1.019 2.424 6.434 3.395 9.051 3.813.72 1.372.198 4.521 2.651 4.521 1.318 0 3.828-1.09 7.76-.418 5.302.903 11.323 12.115 24.648 2.546l.003-.006c2.872-2.062 4.89-3.589 10.997-2.364 4.43.873 3.198-2.238 4.229-4.279z" transform="translate(24 25)" />
                <path fill="#212121" stroke="#222121" strokeWidth="1.5" d="M35.698 68.776c.05.009-.099.018-.254.018-4.734 0-7.857-2.248-10.367-4.056-9.171-6.604-13.298 3.422-15.51-6.532-12.676-2.25-10.186-8.502-6.962-9.037 3.714-.627 6.748-2.746 9.141-5.579 1.57-1.856 3.864-5.723 3.583-6.386-.299-.717-3.006-1.414-3.882-1.766-9.015-3.634-2.6-11.263 2.328-8.93.864.416 1.51.592 1.973.661-.275-4.485-.747-11.024.998-14.998 7.08-16.18 31.12-16.278 38.232.003 1.752 3.986 1.27 10.51.996 14.932 1.46-.364 3.092-1.703 5.692-.627 4.064 1.48 5.074 6.966-2.492 9.341-.687.224-2.534.82-2.773 1.39-.35.837 4.324 10.555 12.733 11.965 1.566.26 2.68 1.662 2.588 3.263-.215 3.796-5.78 5.096-9.557 5.768-.553 2.501-.962 4.647-3.723 4.647-4.722-.278-6.219-2.116-11.786 1.894-3.293 2.355-6.533 4.307-10.958 4.029zm8.92-6.252c6.762-4.862 8.409-3.054 13.818-2.663.648 0 .185-1.114 1.578-4.333 1.183-.236 7.874-1.177 8.663-3.039h-.003l-.042-.364c-10.065-1.692-14.448-12.02-14.63-12.459-2.986-6.144 5.25-6.093 7.383-8.32.867-.908.245-1.703-.806-2.085-1.787-.741-2.385.795-5.173.923-1.426-.062-1.754-.45-2.57-.78.663-9.314 1.578-15.874-4.468-21.698C40.736.366 24.89 1.01 19.483 13.37c-1.629 3.706-.9 11.206-.6 16.056-.577.11-2.194 1.802-6.393-.22-1.15-.548-5.175 1.341.066 3.457 1.41.564 4.602 1.168 5.529 3.391 1.422 3.425-5.496 14.467-14.993 16.063v.003l-.05.37c.842 2.008 8.349 2.94 8.648 3.033 1.386 3.338.49 1.417 1.267 4.094.316 1.076 2.967-1.054 8.427-.104 5.704.971 11.29 11.568 23.234 3.012h0z" transform="translate(24 25)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>;
}