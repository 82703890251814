module.exports = {
  "strings": {
    "try_another_photo": "ลองรูปอื่น",
    "go_back": "ย้อนกลับ",

    "landing__title": "ตรวจสอบดูความแตกต่าง<br />ที่ศิลปินเอไอ (AI) มองเห็นคุณ",
    "landing__select_photo_button": "อัปโหลดภาพ",

    "processing_text_0": "มีบางอย่างที่เหลือเชื่อกำลังจะเกิดขึ้น...",
    "processing_text_1": "เราพนันได้เลยว่าเพื่อนคุณจะต้องชอบมันแน่ ๆ 😍",
    "processing_text_2": "เกือบเสร็จแล้ว อีกแค่สองสามวินาทีแล้วคุณจะประหลาดใจ",
    "processing_text_3": "คุณจินตนาการได้ไหมว่าผลลัพธ์จะดูเป็นอย่างไร?",

    "create__select_one_result": "โปรดเลือกหนึ่งผลลัพธ์ที่คุณชอบมากที่สุดและแตะ 'ถัดไป' เพื่อดำเนินการต่อ",
    "create__select_two_results": "จากนั้นเลือกสองผลลัพธ์ที่คุณชอบมากที่สุดเพื่อสร้างภาพตัดปะของคุณ",
    "create__next_button": "ถัดไป",
    "create__create_collage_button": "สร้างภาพตัดปะ",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "มีข้อผิดพลาดเกิดขึ้น",
  },
};

