module.exports = {
  "strings": {
    "try_another_photo": "尝试另一张照片",
    "go_back": "返回",

    "landing__title": "查看不同的人工智能<br />艺术家是怎么看你的",
    "landing__select_photo_button": "上传照片",

    "processing_text_0": "人生奇迹处处有……",
    "processing_text_1": "我们敢打赌，<br />您的朋友一定会欣喜若狂！😍",
    "processing_text_2": "贴纸马上生成。只需几秒，您就会被惊艳到~",
    "processing_text_3": "您能够想象到贴纸成效吗？",

    "create__select_one_result": "请选择你最喜欢的结果，然后点击‘下一步’继续",
    "create__select_two_results": "现在，选择你最喜欢的两个结果，以创建拼贴画",
    "create__next_button": "下一步",
    "create__create_collage_button": "创建拼贴画",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "发生错误。。。",
  },
};

