module.exports = {
  "strings": {
    "try_another_photo": "Другое фото",
    "go_back": "Назад",
    
    "landing__title": "Искусственный интеллект<br />нарисует вас в разных стилях!",
    "landing__select_photo_button": "Загрузить фото",

    "processing_text_0": "Что-то невероятное уже совсем близко...",
    "processing_text_1": "Ваши друзья это оценят.<br />Даже не сомневайтесь! 😍",
    "processing_text_2": "Почти готово.<br />Еще несколько секунд и вы будете приятно удивлены.",
    "processing_text_3": "Уже представляете,<br />какой получится результат?",

    "create__select_one_result": "Выберите понравившийся результат и нажмите «‎Дальше», чтобы продолжить‎",
    "create__select_two_results": "Теперь выберите два результата, чтобы создать свой коллаж",
    "create__next_button": "Дальше",
    "create__create_collage_button": "Создать коллаж",

    "collage__title": "Save and share",

    "error__error": "Ошибка",

    "internal_error": "Что-то пошло не так...",
  },
};
