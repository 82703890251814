module.exports = {
  "strings": {
    "try_another_photo": "Tentar outra foto",
    "go_back": "Voltar",

    "landing__title": "Confira como diferentes<br />artistas de IA veem você",
    "landing__select_photo_button": "Carregar foto",

    "processing_text_0": "Algo inacreditável está chegando...",
    "processing_text_1": "Aposto que seus amigos<br />vão gostar! 😍",
    "processing_text_2": "Está quase pronto.<br />Só mais alguns segundos e você vai se surpreender.",
    "processing_text_3": "Tem alguma ideia de como<br />vai ser o resultado?",

    "create__select_one_result": "Selecione um resultado preferido e toque em 'Próximo' para prosseguir",
    "create__select_two_results": "Agora selecione dois resultados preferidos para criar sua colagem",
    "create__next_button": "Próximo",
    "create__create_collage_button": "Criar colagem",

    "collage__title": "Save and share",

    "error__error": "Error",

    "internal_error": "Ocorreu um erro...",
  },
};

