import React from "react";
import i18n from "../i18n";

const LOADING_TEXT_INTERVAL = 5000;

export default class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      imageIsLoaded: false,
      textIndex: 0,
    };

    this.updateTextIndex = this.updateTextIndex.bind(this);

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});
  }

  componentDidMount() {
    this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);

    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        this.image.src = this.props.image;
      });
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex() {
    const nextIndex = this.state.textIndex + 1;
    if (nextIndex === 3) {
      clearInterval(this.updateTextIndexTimer);
    }

    this.setState({textIndex: nextIndex});
  }

  render() {
    return <main className="loader">
      <div className="spinonediv-1">
        <div className="spinner-wave">
          <div className="spinner-wave2">
            <div className="spinner-wave3">
              <div className="spinner-wave4">
                <div className="loader-image-container">

                  <img 
                    srcSet="/assets/img/icons/img-pl@2x.jpg?2409 2x, /assets/img/icons/img-pl@3x.jpg?2409 3x"
                    src="/assets/img/icons/img-pl.jpg?2409"
                    alt="logo" />

                  {this.props.image && this.state.imageIsLoaded && <img
                    className="avatar-image"
                    src={this.props.image}
                    alt="" />}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p dangerouslySetInnerHTML={{__html: i18n.t(`processing_text_${this.state.textIndex}`) || ""}} />
    </main>;
  }
}
